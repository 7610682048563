function PageErrBox(data){
    return (
        <div className={`pageErrBox ${data.addClass ? data.addClass : ""}`}>
            {data.icon ? <img src={data.iconUrl ? data.iconUrl : "/assets/images/icon/pageErr.svg"}/> : ""}
            <h1 className="pageErrTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.text ? <p className="pageErrText" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
        </div>
    );
}

export {PageErrBox};