import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing, PageTitle } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import { PageContentsBox, StatePageBox } from "component/app/setting";
import { OptionListItem, PayBox, PayItem, SelectItem, SelectItemArea, SelectItemSection, WashListBox, WashListItem, WashTypeBox, WashTypeItem } from "component/product/service";
import { comFormat } from "js/function";
import {LoadingBox, Popup, SlideUpPopup, ToastPopup} from "component/basic/popup";
import { NotItems } from "component/basic/notItems";
import { Tap } from "component/basic/tap";

const Order = (props) => {
    const navigate = useNavigate();
    const state = useLocation();
    let selId = state?.state?.id||null;
    const selName = state?.state?.name||null;
    const startPage = state?.state?.page||0;
    const carNumber = state?.state?.carNumber||null;
    const cash = state?.state?.cash||null;
    const notMember = state?.state?.notMember||false;
    const productId = state?.state?.productId||null;
    const selProductType = state?.state?.productType||null;

    const [page,setPage] =  useState(startPage == 1 ? 4 : startPage);
    const [id,setId] =  useState(productId);
    const [name,setName] =  useState(selName);
    const [washType,setWashType] =  useState("");
    const [washTypeSel,setWashTypeSel] =  useState("");
    const [price,setPrice] =  useState(0);
    const [pageChk,setPageChk] =  useState(false);
    const [completeContents,setCompleteContents] =  useState("");
    const [couponId,setCouponId] =  useState("");

    const listApi = useGet({
        url:`/product?wash_type=${washTypeSel}&car_number=${carNumber}`,
        loginType:"login"
    });
    const listData = listApi?.data?.products;

    let [productType,setProductType] =  useState(selProductType);

    const [reservationId,setReservationId] =  useState(null);

    const [popupData,setPopupData] =  useState(null);

    const [selOptionItemList,setSelOptionItemList] =  useState([]);
    const [payType,setPayType] =  useState(null);

    const [totalPrice,setTotalPrice] = useState(0);

    const closeCount = useRef(null);
    const [timerCount,setTimerCount] = useState(3);

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [slideUpPopupClose,setSlideUpPopupClose] =  useState(0);

    const apiChk =  useRef(false)

    const optionApi = useGet({
        url:`/product/add?product_id=${id}`,
        loginType:"login"
    });
    const optionData = optionApi?.data?.products;

    function pageMove(type){
        if(!pageChk){
            setPageChk(true);
            if(type == "next"){
                if(page < 4){
                    if(page == 1){
                        setPage(3);
                    }else if(page == 3 && selId && totalPrice == 0){
                        payment();
                    }else{
                        setPage(page + 1);
                    }
                }
            }else{
                if(page > (selId ? 1 : 0)){
                    if(page - 1 == 2){
                        setPage(1);
                    }else{
                        setPage(page - 1);
                    }
                    setPayType(null);
                }
            }

            setTimeout(() => {
                setPageChk(false);
            }, 300);
        }
    }

    function receiptFunc(){
        window.rayno.receipt(localStorage.getItem("token"), reservationId);
        setPage(6);
    }

    window.paymentPopup = (success,msg) => {
        setLoadingBoxOpen(false);

        if (success == true) {
            setPage(5);
        } else {
            setPopupData({
                addClass:"type2",
                title:"결제 실패",
                text:msg||"결제에 실패했습니다.<br/>다시 시도해주세요.",
                icon:"/assets/images/img/err_icon.svg",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{ selId ? navigate(-1) : pageMove("back") },
                btn0:"다시 시도",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    };

    window.barcodeCheck = (barcode) => {
        barcode = JSON.parse(barcode)
        if (barcode?.type === "car_wash") {
            selId = barcode.id;
            productType = 'car_wash';
            payment()
        } else if (barcode?.type === "qr") {
            selId = barcode.id;
            productType = 'qr';
            payment()
        } else if (barcode?.type === "detailing") {
            selId = barcode.id;
            productType = 'detailing';
            payment()
        } else if (barcode?.type === "coupon") {
            if (page == 3) {
                couponCheck(barcode?.id)
            } else {
                setPopupData({
                    addClass:"type2",
                    title:"인식 실패",
                    text:"확인되지 않은 QR 코드입니다.<br/>다시 확인 후 이용해주세요.",
                    icon:"/assets/images/img/err_icon.svg",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{ setPopupData(null)},
                    btn0:"다시 시도",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            }
        } else {
            setPopupData({
                addClass:"type2",
                title:"인식 실패",
                text:"확인되지 않은 QR 코드입니다.<br/>다시 확인 후 이용해주세요.",
                icon:"/assets/images/img/err_icon.svg",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{ setPopupData(null)},
                btn0:"다시 시도",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    };

    function couponCheck(couponId){
        if (!apiChk.current) {
            apiChk.current = true;

            let formData = new FormData();
            formData.append("payment_price", totalPrice);
            formData.append("coupon_id", couponId);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/coupon/check`,
                success: (data) => {
                    apiChk.current = false;
                    if (data?.success) {
                        setCouponId(couponId)
                        setTotalPrice(totalPrice - data?.data?.coupon_price)
                    } else {
                        setPopupData({
                            addClass:"type2",
                            title:"인식 실패",
                            text:"확인되지 않은 QR 코드입니다.<br/>다시 확인 후 이용해주세요.",
                            icon:"/assets/images/img/err_icon.svg",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{ setPopupData(null)},
                            btn0:"다시 시도",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                },
                err: (data) => {
                    apiChk.current = false;
                    setPopupData({
                        addClass:"type2",
                        title:"인식 실패",
                        text:"확인되지 않은 QR 코드입니다.<br/>다시 확인 후 이용해주세요.",
                        icon:"/assets/images/img/err_icon.svg",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{ setPopupData(null)},
                        btn0:"다시 시도",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            })
        }
    }

    function payment(){
        if (!apiChk.current) {
            apiChk.current = true;
            
            let formData = new FormData();
            formData.append("type", selId ? 2 : 1);
            formData.append("car_number", carNumber);
            formData.append("product_type", productType);
            formData.append("wash_type", washType);
            formData.append("cam_id", process.env.REACT_APP_CAM);
            formData.append("coupon_id", couponId);

            if (selId) {
                formData.append("car_wash_reservation_id", selId);
            } else {
                formData.append("product_id", id);
            }

            if (!selId || (selId && selOptionItemList?.length > 0)) {
                formData.append("payment_method", payType);
            }

            selOptionItemList.forEach(function(item,i){
                formData.append("product_add_ids[]", item.id);
            });

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/payment`,
                success: (data) => {
                    apiChk.current = false;
                    setReservationId(data?.data?.id);
                    setCompleteContents(data?.data?.complete_contents)

                    if((selId && totalPrice == 0) || payType == "cash"){
                        setCompleteContents("안내에 따라 차량을 이동시켜주세요.")
                        setPage(6);
                    } else {
                        setPage(4);
                        setLoadingBoxOpen(true);
                    }

                    if (data?.data?.status == 20 && totalPrice > 0) {
                        if (payType == "applePay") {
                            window.rayno.paymentNFC(localStorage.getItem("token"), data?.data?.id, selId ? 2 : 1)
                        } else if(payType == "card")  {
                            window.rayno.payment(localStorage.getItem("token"), data?.data?.id, selId ? 2 : 1);
                        }
                    }
                },
                err: (data) => {
                    apiChk.current = false;
                    setPayType(null);
                    
                    setPopupData({
                        addClass:"type2",
                        title:data.alert||"결제 실패",
                        text:data.data||"결제에 실패했습니다.<br/>다시 시도해주세요.",
                        icon:"/assets/images/img/err_icon.svg",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{ selId ? navigate(-1) : pageMove("back") },
                        btn0:"다시 시도",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            })
        }
    }

    useEffect(() => {
        if(payType){
            payment();
        }
    }, [payType]);

    useEffect(() => {
        if(optionData){
            optionData?.forEach(function(item,i){
                if(item.essential_type == 1){
                    let itemArr = [...selOptionItemList];
                    itemArr.push(item);
                    setSelOptionItemList(itemArr);
                }
            });
        }
    }, [optionApi]);

    useEffect(() => {
        let total = 0;
        total = Number(price) + total;
        if(selOptionItemList.length > 0){
            selOptionItemList.forEach(function(item,i){
                if(item.essential_type !== 1){
                    total = total + Number(item.price);
                }
                if(selOptionItemList.length - 1 == i){
                    setTotalPrice(total);
                }
            });
        }else{
            setTotalPrice(total);
        }
    }, [price,selOptionItemList]);

    useEffect(() => {
        if(closeCount.current !== null){
            clearInterval(closeCount.current);
            closeCount.current = null;
        }

        if(page == 5 || page == 6){
            let countType = page == 5 ? 60 : 3;
            setTimerCount(countType);
            
            if(closeCount.current !== null){
                clearInterval(closeCount.current);
                closeCount.current = null;
            }

            function timerChk(duration) {
                let timerNumber = duration;
                closeCount.current = setInterval(function() {
                    setTimerCount(timerNumber);
                    if (--timerNumber < 0) {
                        timerNumber = 0;
                        clearInterval(closeCount.current);
                        closeCount.current = null;

                        navigate("/carNumber")
                    }
                }, 1000);
            }

            timerChk(countType);
        }

        if(page == 0 || page == 1){
            setSelOptionItemList([]);
            setPayType(null);
            setId(productId);
            setName(selName);
            setPrice(0);

            if(page == 0){
                setWashTypeSel("");
            }
        }
        setSlideUpPopupClose(slideUpPopupClose + 1);
    }, [page]);

    useEffect(() => {
        if(startPage == 1){
            setTimeout(() => {
                payment();
            }, 100);
        }
    }, []);
    
    return (
        <PageSizing>
            <Header
                logoClickChk={false}
                carNumber={page <= 3 ? carNumber : null}
            />
            <ContentsSection addClass={`headerContents btnContents ${page >= 2 && page <= 3 ? "slidePopupContents" : ""}`}>
                {page <= 3 ? 
                <PageTitle
                    title={page == 0 ? "원하시는 세차 방식을 선택하세요." :page == 1 ? "이용하고자 하는 서비스를 선택하세요." : page == 2 ? "추가 옵션을 선택하세요." : page == 3 ? "결제하실 수단을 선택해주세요." : ""}
                    subTitle={page == 0 ? `세차 방식 선택` : page == 1 ? `이용권 선택` : page == 2 ? "옵션 선택" : page == 3 ? "결제수단 선택" : ""}
                >
                    <div className="orderCountBox">
                        <div className="orderCount" style={{"background":`conic-gradient(#73F7CA ${360 * (Number(page+1||1) / (page <= 1 ? 3 : 4))}deg, #464650 ${360 * (Number(page+1||1) / 4)}deg)`}}><span>{Math.floor(Number(page+1||1) / (page <= 1 ? 3 : 4) * 100)}%</span></div>
                    </div>
                </PageTitle>
                :""}
                {page == 0 ?
                    <WashTypeBox>
                        <WashTypeItem
                            addClass=""
                            selType={washTypeSel}
                            val={"outside"}
                            func={()=>{setWashTypeSel("outside");setPage(1)}}
                            name={"외부세차"}
                            text={"프리미엄 브러시와 9종 이상의 고성능 케미컬을 적용한 오토노바 카워시 서비스"}
                            img={"/assets/images/wash/washType_0.svg"}
                        />
                        <WashTypeItem
                            addClass=""
                            selType={washTypeSel}
                            val={"out_inside"}
                            func={()=>{setWashTypeSel("out_inside");setPage(1)}}
                            name={"외부세차+내부세차"}
                            text={"외부세차 후, 오토노바 프로 디테일링 크루들이 진행하는 빠르고 깨끗한 내부 세차 서비스"}
                            img={"/assets/images/wash/washType_1.svg"}
                        />
                        <WashTypeItem
                            addClass=""
                            selType={washTypeSel}
                            disabled={listApi?.data?.beauty_check > 0 ? false : true}
                            val={"beauty"}
                            func={()=>{setWashTypeSel("beauty");setPage(1)}}
                            name={"뷰티 팩토리"}
                            text={"첨단 계축 장비와 도장 팡별 프로그램을 통한 시스템 폴리싱 및 정밀 디테일링 서비스"}
                            img={"/assets/images/wash/washType_2.svg"}
                        />
                    </WashTypeBox>
                :""}
                {page == 1 ?
                    <WashListBox>
                        {listData && listData?.length > 0 ? 
                            <>{listData?.map((item,i)=>(
                                <WashListItem
                                    key={i}
                                    id={item.id}
                                    type="wash"
                                    name={item.name||""}
                                    badges={item?.product_badges}
                                    caption={item.contents||""}
                                    option={item.product_options||[]}
                                    subOptionTitle={item.relation_product_name||null}
                                    subOption={item.relation_product_options||[]}
                                    price={item.price}
                                    discountPrice={item.discount_price}
                                    discountSedanPrice={item.discount_sedan_price}
                                    discountSuvPrice={item.discount_suv_price}
                                    discountLargetSizePrice={item.discount_large_size_price}
                                    useCarSize={item.use_car_size}
                                    sedanPrice={item.sedan_price}
                                    suvPrice={item.suv_price}
                                    largeSizePrice={item.large_size_price}
                                    selFunc={(selWashId,selPrice,selWashType)=>{
                                        setId(item.id);
                                        setName(item.name);
                                        setPrice(selPrice);
                                        setWashType(selWashType);
                                        setProductType("wash");
                                        // setPage(2);
                                        setPage(3);
                                    }}
                                />
                            ))}</>
                        : 
                            <NotItems img="/assets/images/img/err_icon.svg" text={"지금은 뷰티 팩토리 서비스 이용이 어려워요.<br>앱을 통해 예약하거나, 다음에 이용해 주세요."}/>
                        }
                    </WashListBox>
                :""}
                {page == 2 ? 
                    <PageContentsBox addClass="pageContentsScrollBox">
                        {optionData && optionData?.length > 0 ? 
                            <>
                                {optionData?.map((item,i)=>(
                                    <OptionListItem
                                        key={i}
                                        addClass={item.essential_type == 1 ? "essentialSel" : ""}
                                        name={item.name}
                                        info={item.contents}
                                        selType={item.essential_type == 1 ? true : selOptionItemList?.filter((el)=>el.id == item.id)[0] ? true : false}
                                        btnText={item.essential_type == 1 ? "자동선택" : selOptionItemList?.filter((el)=>el.id == item.id)[0] ? "선택완료" : `${comFormat(item.price)}원`}
                                        disabled={item.essential_type == 1 ? true : false}
                                        func={()=>{
                                            let itemArr = [...selOptionItemList];
                                            if(selOptionItemList?.filter((el)=>el.id == item.id)[0]){
                                                itemArr = selOptionItemList?.filter((el)=>el.id !== item.id);
                                            }else{
                                                itemArr.push(item);
                                            }
                                            
                                            setSelOptionItemList(itemArr);
                                        }}
                                    />
                                ))}
                            </>
                        :
                            <NotItems img="/assets/images/img/err_icon.svg" text="선택 가능한 옵션이 없습니다."/>
                        }
                    </PageContentsBox>
                :""}
                {page == 3 ? 
                    <PayBox>
                        <PayItem
                            addClass="cardItem"
                            img={"/assets/images/icon/card.png"}
                            text={"신용/체크카드"}
                            subText={null}
                            selType={payType == "card" ? "active" : ""}
                            func={()=>{setPayType("card")}}
                        />
                        {notMember ? "" : 
                        <PayItem
                            addClass="cashItem"
                            img={"/assets/images/icon/cash.png"}
                            text={"캐시 사용"}
                            disabled={totalPrice > Number(cash) || Number(cash) == 0 || !cash ? true : false}
                            subText={`잔여캐시 <span class='gTextColor'>${comFormat(cash)}C</span>`}
                            selType={payType == "cash" ? "active" : ""}
                            func={()=>{setPayType("cash")}}
                        />}
                        <PayItem
                            addClass="appleItem"
                            img={"/assets/images/icon/applepay.png"}
                            text={"애플페이/삼성페이"}
                            subText={null}
                            selType={payType == "applePay" ? "active" : ""}
                            func={()=>{setPayType("applePay")}}
                        />
                    </PayBox>
                :""}
                {page == 4 && totalPrice > 0 ? 
                    <StatePageBox
                        addClass="statePage_cardImg"
                        icon={false}                
                        title={"카드를 리더기에 넣어주세요."}
                        subTitle={"신용/체크카드 결제"}
                        img="/assets/images/img/card.png"
                    />
                :""}
                {page == 5 ? 
                    <StatePageBox
                        addClass="statePage_receiptImg"
                        icon={true}                
                        title={"영수증을 출력해드릴까요?"}
                        subTitle={totalPrice > 0 ? "결제 완료!" : "사용 완료!"}
                        img="/assets/images/img/receipt.png"
                    />
                :""}
                {page == 6 ? 
                    <StatePageBox
                        addClass="fullImg"
                        icon={true}                
                        title={completeContents}
                        subTitle={"이용해주셔서 감사합니다!"}
                        img="/assets/images/img/car.png"
                    />
                : ""}
            </ContentsSection>
            {page >= 2 && page <= 3 ? 
            <SlideUpPopup
                addClass=""
                title={
                    <SelectItem
                        mainItem={true}
                        name={"총 결제 금액"}
                        value={`${comFormat(totalPrice)}원`}
                    />
                }
                slideUpPopupClose={slideUpPopupClose}
            >
                <SelectItemSection>
                    <SelectItemArea
                        title="선택한 서비스"
                    >
                        <SelectItem
                            mainItem={false}
                            name={name}
                            value={selId ? null : `${comFormat(price)}원`}
                        />
                    </SelectItemArea>
                    {/* <SelectItemArea
                        title="선택한 옵션"
                    >
                        {selOptionItemList && selOptionItemList.length > 0 ? 
                            <>
                                {selOptionItemList?.map((item,i)=>(
                                    <SelectItem
                                        key={i}
                                        mainItem={false}
                                        name={item.name}
                                        value={item.essential_type == 1 ? null : `${comFormat(item.price)}원`}
                                    />
                                ))}
                            </>
                            :
                            <NotItems img="/assets/images/img/err_icon.svg" addClass="minVar" text="선택한 옵션이 없습니다."/>
                        }
                    </SelectItemArea> */}
                    <SelectItemArea
                        title={null}
                    >
                        <SelectItem
                            mainItem={true}
                            name={"총 결제 금액"}
                            value={`${comFormat(totalPrice)}원`}
                        />
                    </SelectItemArea>
                </SelectItemSection>
            </SlideUpPopup>
            :""}
            <BtnBox
                boxType="fixed"
                addClass={(((page == 0 && !notMember) || page == 1 || page == 2 || page == 5)) ? "col2" : ""}
            >
                {(page == 0) || page == 1 || (page == 2 && selId) ?
                <>
                    {(page != 0 || (page == 0 && !notMember)) &&
                    <BtnItem
                        addClass={"subBtn"}
                        contents={"뒤로"}
                        disabled={false}
                        func={() => {page == 0 ? navigate(-1) : pageMove("back")}}
                    />}
                    <BtnItem
                        addClass={(page == 0 && notMember) ? "subBtn" : ""}
                        contents={"<img src='/assets/images/icon/ch.svg'/>차량번호 변경"}
                        disabled={false}
                        func={() => {
                            setPopupData({
                                addClass:"type2",
                                title:"차량번호 수정",
                                text:"고객님의 차량번호와 다르신가요?<br/>수정 버튼을 클릭해<br/>차량번호를 수정해주세요.",
                                icon:"/assets/images/img/number_ch_icon.svg",
                                closeType:false,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btn0:"취소",
                                btn0Type:"gColor",
                                btn1:"수정",
                                btn1Type:"",
                                btnFunc1:()=>{
                                    navigate("/carNumber")
                                }
                            });
                        }}
                    />
                </>
                :""}
                {page == 2 || page == 3 || page == 4 ?
                <>
                    {page == 2 && selId ? "" : 
                        <BtnItem
                            addClass={page !== 3 && page !== 4 ? "subBtn" : ""}
                            contents={"뒤로"}
                            disabled={false}
                            func={() => {pageMove("back")}}
                        />
                    }
                    {page !== 3 && page !== 4 ? 
                        <BtnItem
                            addClass=""
                            contents={"다음으로"}
                            disabled={false}
                            func={() => {pageMove("next")}}
                        />
                    :""}
                </>
                : page == 5 ? 
                    <>
                        <BtnItem
                            addClass={"subBtn"}
                            contents={"종료"}
                            disabled={false}
                            func={() => {setPage(6)}}
                        />
                        <BtnItem
                            addClass={""}
                            contents={"영수증 출력"}
                            disabled={false}
                            func={() => {receiptFunc()}}
                        />
                    </>
                : page == 6 ? 
                    <>
                        <BtnItem
                            addClass={""}
                            contents={`<p class='closeCountItem' style="background:conic-gradient(#fff ${360 * (Number(timerCount) / 3)}deg, #73F7CA ${360 * (Number(timerCount) / 3)}deg)"><span>${timerCount}</span></p> 초 뒤 종료됩니다.`}
                            disabled={false}
                            func={() => {}}
                        />
                    </>
                :""
                }
            </BtnBox>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Order;