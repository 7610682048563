function PageSizing(data){
    return (
        <div className={`pageSizing ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function ContentsSection(data){
    return (
        <div className={`contentsSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function PageTitle(data){
    return (
        <div className={`pageTitleBox ${data.addClass||""}`}>
            <div className="pageTitleTextBox">
                {data.subTitle ? <p className="pageSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
                <h1 className="pageTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            </div>
            {data.children}
        </div>
    );
}

function PageLine(data){

    return (
        <span className={`pageLine ${data.addClass||""}`}></span>
    );
}

export {PageSizing,ContentsSection,PageTitle,PageLine};