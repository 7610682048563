import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing, PageTitle } from "component/app/items";
import { Header } from "component/elements/header";
import { Keypad, KeypadTitle, PageContentsBox, PageInputItem, PwItem } from "component/app/setting";

const SettingLogin = (props) => {
    const navigate = useNavigate();

    const [pw,setPw] =  useState("");
    const [errMsg,setErrMsg] =  useState(null);
    const [apiChk,setApiChk] =  useState(false);

    useEffect(() => {
        setErrMsg(null);

        if(pw.length == 6){
            setApiChk(true);
            let formData = new FormData();
            formData.append("password", pw);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/password/check`,
                success: (data) => {
                    setApiChk(false);
                    navigate("/setting");
                },
                err: (data) => {
                    setApiChk(false);
                    if(data.data || data.alert){
                        setErrMsg(data.data);
                    }
                }
            })
        }
    }, [pw]);

    return (
        <PageSizing>
            <Header
                logoClickChk={false}
                carNumber={null}
            />
            <ContentsSection addClass="headerContents">
                <PageTitle
                    addClass={"flexTitle"}
                    title={"<img src='/assets/images/icon/setting.svg'/>키오스크 관리 페이지"}
                    subTitle={"관리자 전용"}
                />
                <PageContentsBox>
                    <PageInputItem addClass={errMsg ? "err" : ""}>
                        <PwItem data={pw}/>
                    </PageInputItem>
                    <KeypadTitle
                        title={"<span class='gTextColor'>관리자 비밀번호</span>를 입력해주세요."}
                        subTitle={null}
                        errMsg={errMsg}
                    />
                    <Keypad
                        type={"number"}//number, text, textSub
                        textDisabled={false}
                        nextChk={false}
                        textVal={null}
                        data={pw}
                        textFunc={(val)=>{
                            if(pw.length < 6 && !apiChk){
                                setPw(String(pw) + String(val))
                            }
                        }}
                        textSubFunc={(val)=>{
                            if(pw.length < 6 && !apiChk){
                                setPw(String(pw) + String(val))
                            }
                        }}
                        backFunc={()=>{
                            if(pw.length > 0 && !apiChk){
                                setPw(pw.slice(0,-1))
                            }
                        }}
                        nextFunc={()=>{}}
                        allDel={()=>{
                            if(!apiChk){
                                setPw("")
                            }
                        }}
                    />
                </PageContentsBox>
            </ContentsSection>
        </PageSizing>
    );
};

export default SettingLogin;