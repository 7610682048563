import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageLine, PageSizing, PageTitle } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import { PageContentsBox, SettingMenuItem } from "component/app/setting";
import { BottomErrMsg, Popup, SelPopup } from "component/basic/popup";
import settingData from "../../data/data.json";
import { InputBoxOnly, InputItemBox, InputNameOnly } from "component/basic/formItems";
import { appClose, setDateTime } from "js/function";

const Setting = (props) => {
    const navigate = useNavigate();

    const timeApi = useGet({
        url:`/auto/end`,
        loginType:"login"
    });
    const timeData = timeApi?.data?.kiosk_end_time;

    const [time,setTime] =  useState("");
    const [popupData,setPopupData] =  useState(null);
    const [selPopup,setSelPopup] =  useState(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    useEffect(() => {
        if(sessionStorage.getItem("pwChChk")){
            setBottomMsgData({
                text : "<img src='/assets/images/icon/msg_icon.svg'/>비밀번호 변경이 완료되었습니다.",
                chk : bottomMsgData.chk + 1
            });
            sessionStorage.removeItem("pwChChk")
        }
    }, []);

    useEffect(() => {
        setTime(timeData||"");
    }, [timeApi]);

    function endTimeFunc(timeVal){
        let formData = new FormData();
        formData.append("end_time", timeVal);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: `/auto/end`,
            success: (data) => {
                navigate("/setting");
            },
            err: (data) => {
                if(data.data || data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert||"",
                        text:data.data||"",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    return (
        <PageSizing>
            <Header
                logoClickChk={false}
                carNumber={null}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    addClass={"flexTitle"}
                    title={"<img src='/assets/images/icon/setting.svg'/>키오스크 관리 페이지"}
                    subTitle={"관리자 전용"}
                />
                <PageContentsBox>
                    <InputBoxOnly>
                        <InputNameOnly name="자동 종료시간 설정" addClass="notAni"/>
                        <InputItemBox
                            type=""
                            selItem={true}
                            addClass="type2"
                            inputName="자동 종료 시간"
                            placeholder="자동 종료 시간"
                            value={time}
                            readOnly={true}
                            func={(e) => {}}
                            clickInput={(e) => setSelPopup({
                                title:"자동 종료시간 설정",
                                addClass:null,
                                closeType:true,
                                items:settingData.time,
                                val:settingData.time,
                                sel:time,
                                closeFunc:(e) => {},
                                func:(e) => {
                                    setTime(e.text);
                                    endTimeFunc(e.text);
                                }
                            })}
                        />
                    </InputBoxOnly>
                    <PageLine/>
                    <SettingMenuItem text="비밀번호 재설정" func={(e) => {navigate("/setting/pwEdit")}}/>
                    <SettingMenuItem text="서비스 재시작" func={(e) => {
                        setPopupData({
                            addClass:null,
                            title:"서비스 재시작",
                            text:"정말 서비스를 다시 시작하시겠어요?",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"취소",
                            btn0Type:"gColor",
                            btn1:"재시작",
                            btn1Type:"",
                            btnFunc1:()=>{appClose("reStart")}
                        });
                    }}/>
                    <SettingMenuItem text="서비스 종료" func={(e) => {
                        setPopupData({
                            addClass:null,
                            title:"서비스 종료",
                            text:"정말 서비스를 종료하시겠어요?",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"취소",
                            btn0Type:"gColor",
                            btn1:"종료",
                            btn1Type:"",
                            btnFunc1:()=>{appClose("close")}
                        });
                    }}/>
                </PageContentsBox>
            </ContentsSection>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"<img src='/assets/images/icon/out.svg'/>나가기"}
                    disabled={false}
                    func={() => {navigate("/carNumber")}}
                />
            </BtnBox>
            <SelPopup
                data={selPopup}
            />
            <Popup
                data={popupData}
            />
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </PageSizing>
    );
};

export default Setting;