//버튼 상단 텍스트
function BtnText(data){
    return (
        <p className={`btnTextType ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.text}}></p>
    );
}

//버튼 박스
function BtnBox(data){
    return (
        <div className={`${data.boxType == "fixed" ? "pageBtn_box" : "btn_box"} ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function BtnSubBox(data){
    return (
        <div className={`btn_subBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//버튼
function BtnItem(data){
    return (
        <>
        {data.btnType == "link" ? 
        <a href={data.btnLink} target="_blank" className={`pageBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></a>
        :
        <button type="button" className={`pageBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></button>
        }
        </>
    );
}

//토클버튼
function ToggleBtn(data){
    return (
        <div className="toggleBox">
            <input type="checkbox" onChange={(e)=>{data.chkFunc(e.target.checked)}} id={data.id} checked={data.checkedType} className="toggleChk"/>
            <label htmlFor={data.id}>
                <span></span>
            </label>
        </div>
    );
}

//텍스트 버튼
function TextBtnItemBox(data){
    return (
        <div className={`textBtnItemBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//텍스트 버튼
function TextBtnItem(data){
    return (
        <button type="button" className={`textBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
            <p className="textBtn_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
            {data.addClass == "underLine" ? "" : <img src="/assets/images/basic/link_icon.svg"/>}
        </button>
    );
}

export {BtnBox,BtnSubBox,BtnItem,BtnText, ToggleBtn,TextBtnItemBox,TextBtnItem};