import useDoubleClick from "component/app/doubleClick";
import { useNavigate } from "react-router-dom";

function Header(headerData){
    let navigate = useNavigate();
    const clickChk = useDoubleClick(clickCallback,10,1000);
    
    function clickCallback(){
        navigate("/setting/login");
    }
    
    return (
        <div className="header">
            <div className={`headerSection ${headerData.addClass ? headerData.addClass : ""}`}>
                {headerData.logoClickChk ? 
                    <button type="button" className="btnLogo btnHeaderLeft" onClick={clickChk}><img src="/assets/images/app/logo_min.svg"/></button>
                    :
                    <div type="button" className="btnLogo btnHeaderLeft"><img src="/assets/images/app/logo_min.svg"/></div>
                }
                {headerData.carNumber ? <div className="headCarNumberBox btnHeaderRight"><p className="headCarNumber">{headerData.carNumber}</p></div> : ""}
                {headerData.children}
            </div>
        </div>
    );
}

export {Header};