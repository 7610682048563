import Private from "pages/private/Private";
import PublicOnly from "pages/private/publicOnly";
import React, { useEffect, useState } from "react";
import * as fatchSet from "../api/api";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "component/basic/scrollTopSet";
import { Popup, RightPopup } from "component/basic/popup";
import useGet from "api/useGet";
import { ErrorBoundary } from "react-error-boundary";
import Main from "pages/main/Main";
import Login from "pages/service/Login";
import SettingLogin from "pages/setting/Login";
import { appClose } from "js/function";
import Setting from "pages/setting/Setting";
import PwEdit from "pages/setting/PwEdit";
import CarNumber from "pages/service/CarNumber";
import Service from "pages/service/Service";
import Order from "pages/service/Order";

const Router = () => {
    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);

    const [netWork, setNetWork] = useState(navigator.onLine);
    const [popupData,setPopupData] =  useState(null);

    window.logIn = function(){
        setToken(true);
    }
    window.logOut = function(){
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        setToken(false);
    }

    const netWorkChange = () => {
        setNetWork(navigator.onLine);
        if(navigator.onLine){
            setPopupData(null)
        }else{
            setPopupData({
                addClass:null,
                title:"인터넷 연결 실패",
                text:"네트워크 환경을 확인해주세요.",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{
                    appClose("close")
                },
                btn0:"종료",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    }
    useEffect(() => {
        window.addEventListener("online", netWorkChange);
        window.addEventListener("offline", netWorkChange);
        return() => {
          window.removeEventListener("online", netWorkChange);
          window.removeEventListener("offline", netWorkChange);
        };
    }, []);

    //로그인,비로그인 무관 페이지
    const publicRoutes = [
        {
            exact: true,
            path: "/",
            component: <Navigate to="/main" />,
        },
        {
            exact: true,
            path: "/main",
            component: <Main/>,
        },
        {
            exact: true,
            path: "/service/login",
            component: <Login/>,
        },
    ];
    //비로그인 한정 페이지
    const publicRoutesOnly = [
        
        
    ];
    //로그인 한정 페이지
    const privateRoutes = [
        {
            exact: true,
            path: "/setting/login",
            component: <SettingLogin/>,
        },
        {
            exact: true,
            path: "/setting",
            component: <Setting/>,
        },
        {
            exact: true,
            path: "/setting/pwEdit",
            component: <PwEdit/>,
        },
        {
            exact: true,
            path: "/carNumber",
            component: <CarNumber/>,
        },
        {
            exact: true,
            path: "/service",
            component: <Service/>,
        },
        {
            exact: true,
            path: "/service/order",
            component: <Order/>,
        },
    ];

    //백화 시 노출
    const ErrorFallback = (err) => {
        console.log(err)
        return (
            <div className="pageSizing">
                <div className="pageErrBox">
                    <h1 className="pageErrTitle">서비스 이용에 불편을 드려 죄송합니다.</h1>
                    <p className="pageErrText">서비스 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요</p>
                </div>
                <div className="pageBtn_box">
                    <button type="button" className="pageBtn" onClick={() => window.location.href = "/"}>처음으로</button>
                </div>
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    {/* 기본접근 */}
                    {publicRoutes.map(
                        ({ exact, path, component }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={component}
                            />
                        )
                    )}

                    {/* 비로그인만 접근 */}
                    {publicRoutesOnly.map(
                        ({ exact, path, component }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={!token ? component : <PublicOnly/>}
                            />
                        )
                    )}

                    {/* 로그인만 접근 */}
                    {privateRoutes.map(
                        ({ exact, path, component }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={token ? component : <Private/>}
                            />
                        )
                    )}

                    {/*404*/}
                    <Route
                        key={`public-route-/*`}
                        exact={true}
                        path="/*"
                        element={<PublicOnly/>}
                    />
                </Routes>
                <Popup
                    data={popupData}
                />
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Router;