import { classChk, comFormat, slideToggle, washIcon } from "js/function";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CountInput from "./count";

function WashTypeCaption(data){

    return (
        <p className={`washTypeCaption ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.type}}/>
    );
}

function TicketItem(data){

    return (
        <div className={`ticketItem ${data.addClass||""}`}>
            <div className="ticketItem_titleBox">
                {data?.type ? <WashTypeCaption type={data?.type == "once" ? "1회권" : "정기권"} addClass={data?.type == "once" ? "bColor" : "gColor"}/> : ""}
                {data?.expiration_date ? <WashTypeCaption type={'D-' + data?.expiration_date} addClass={"cColor"}/> : ""}
                {data.title}
            </div>
            {data.text ? 
                <p className="ticketItem_info" dangerouslySetInnerHTML={{__html:data.text}}/>
            :""}
            {data.btnText ? 
            <button type="button" onClick={()=>{data.func()}} disabled={data.disabled||false} className={`ticketItem_btn`}>
                {data.btnText}
                <img src="/assets/images/icon/move.svg"/>
            </button>
            :""}
        </div>
    );
}

function WashListOptionItem(data){

    return (
        <div className="washListOptionItem">
            {washIcon(data.name) ? <img src={washIcon(data.name)}/> : ""}
            <p className="washListOptionItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
        </div>
    );
}

function WashListBtn(data){

    return (
        <button type="button" className={`washListBtn ${data.addClass||""} ${data.isUse ? "washListUseBtn" : ""} ${data.textType ? "washListTextBtn" : ""}`} disabled={data.disabled||false} onClick={()=>{data.func()}} dangerouslySetInnerHTML={{__html:`${data.text}${data.disabled || data.textType ? "" : `<img src="/assets/images/icon/btn_icon.svg"/>`}`}}/>
    );
}

function DetailingTextItem(data){

    return (
        <div className="detailingTextItem">
            <p className="detailingTextItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="detailingTextItem_value" dangerouslySetInnerHTML={{__html:data.val}}/>
        </div>
    );
}

function WashListItem(data){
    let navigate = useNavigate();

    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);

    const [slideChk,setSlideChk] = useState(true);

    useEffect(()=>{
        
    },[])

    return (
        <div className={`washListItem ${data.addClass || ""}`}>
            <div className="washListItem_nameBox">
                <div className="washListItem_badgeBox">
                    {data?.badges?.length > 0 && data?.badges?.map((badge) => (
                        <div className="washListItem_badge" style={{ backgroundColor: badge?.color + '99', border: "3px solid " + badge?.color }}>{badge?.name}</div>
                    ))}
                </div>
                <h1 className="washListItem_name">{data.name}</h1>
                <p className="washListItem_caption" dangerouslySetInnerHTML={{__html: data.caption}}/>
            </div>
            {data.type == "wash" ?
                <>
                    <div className="washListItem_optionBox">
                        {data.option.map((item, i) => (
                            <WashListOptionItem
                                key={i}
                                id={item.id}
                                name={item.name}
                            />
                        ))}
                    </div>
                    {data.subOptionTitle ?
                        <div className={`washListItem_subOptionBox ${openChk ? "active" : ""}`}>
                            {data.subOption ?
                                <>
                                    <button type="button" className="washListItem_subOptionTitle" onClick={() => {
                                        if (slideChk) {
                                            setSlideChk(false);
                                            setTimeout(function () {
                                                slideToggle(slideItem.current, "flex")
                                                setOpenChk(!openChk)
                                            }, 0);
                                            setTimeout(() => {
                                                setSlideChk(true);
                                            }, 300);
                                        }
                                    }} dangerouslySetInnerHTML={{__html: `${data.subOptionTitle} 서비스 포함`}}/>
                                    <div className="washListItem_subOption" ref={slideItem}>
                                        {data.subOption.map((item, i) => (
                                            <WashListOptionItem
                                                key={i}
                                                id={item.id}
                                                name={item.name}
                                            />
                                        ))}
                                    </div>
                                </>
                                :
                                <h1 className="washListItem_subOptionTitle"
                                    dangerouslySetInnerHTML={{__html: data.subOptionTitle}}/>
                            }
                        </div>
                        : ""}
                </>
                :
                <>{data.requiredTime || data.service || data.notes ?
                    <div className="detailingTextBox">
                        {data.requiredTime ?
                            <DetailingTextItem name="소요시간" val={data.requiredTime}/>
                            : ""}
                        {data.service ?
                            <DetailingTextItem name="포함된 서비스" val={data.service}/>
                            : ""}
                        {data.notes ?
                            <DetailingTextItem name="참고사항" val={data.notes}/>
                            : ""}
                    </div>
                    : ""}</>
            }
            <div className={`washListItem_btnBox ${data.useCarSize === 1 ? "col2" : ""}`}>
                {(data.useCarSize === 0) ?
                    <WashListBtn
                        addClass={""}
                        text={data?.discountPrice ? (`<div>1회권 <del>${comFormat(data.price)}원</del> <img class="image-inline" src="/assets/images/icon/btn_icon.svg"> <b>${comFormat(data?.discountPrice)}원 (할인가 적용)</b></div>`) : (`1회권 ${comFormat(data.price)}원`)}
                        disabled={false}
                        func={() => {
                            data.selFunc(data.id, data?.discountPrice ? data?.discountPrice : data.price, "once");
                        }}
                    />
                    :
                    <>
                        {data.sedanPrice === data.suvPrice ?
                            <>
                                {data.sedanPrice == 0 || data.sedanPrice ?
                                    <WashListBtn
                                        addClass={"r2"}
                                        text={`<span><b>세단/SUV</b><br/>${comFormat(data.price + data.sedanPrice)}원</span>`}
                                        disabled={false}
                                        func={()=>{
                                            data.selFunc(data.id,data.price + data.sedanPrice,"sedan");
                                        }}
                                    />
                                    :""}
                            </>
                            :
                            <>
                                {data.sedanPrice == 0 || data.sedanPrice ?
                                    <WashListBtn
                                        addClass={"r2"}
                                        text={`<span><b>세단</b><br/>${comFormat(data.price + data.sedanPrice)}원</span>`}
                                        disabled={false}
                                        func={()=>{
                                            data.selFunc(data.id,data.price + data.sedanPrice,"sedan");
                                        }}
                                    />
                                    :""}
                                {data.suvPrice == 0 || data.suvPrice ?
                                    <WashListBtn
                                        addClass={"r2"}
                                        text={`<span><b>SUV</b><br/>${comFormat(data.price + data.suvPrice)}원</span>`}
                                        disabled={false}
                                        func={()=>{
                                            data.selFunc(data.id,data.price + data.suvPrice,"suv");
                                        }}
                                    />
                                    :""}
                            </>
                        }
                        {data.largeSizePrice == 0 || data.largeSizePrice ?
                            <WashListBtn
                                addClass={"r2"}
                                text={`<span><b>밴/트럭/MPV</b><br/>${comFormat(data.price + data.largeSizePrice)}원</span>`}
                                disabled={false}
                                func={()=>{
                                    data.selFunc(data.id,data.price + data.largeSizePrice,"large");
                                }}
                            />
                            :""}
                    </>
                }
            </div>
        </div>
    );
}

function WashListBox(data) {

    return (
        <div className="washListBox orderScrollBox">
            {data.children}
        </div>
    );
}

function OptionListBox(data){

    return (
        <div className="optionListBox">
            {data.children}
        </div>
    );
}

function OptionListItem(data){

    return (
        <div className={`optionListItem ${data.addClass||""}`}>
            <div className="optionListItem_nameBox">
                <h1 className="optionListItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
                <p className="optionListItem_info" dangerouslySetInnerHTML={{__html:data.info}}/>
            </div>
            {data.btnText ? 
            <button type="button" onClick={()=>{data.func()}} disabled={data.disabled||false} className={`optionListItem_btn ${data.selType ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.selType ? "option_on" : "option_off"}.svg`}/>
                {data.btnText}
            </button>
            :""}
        </div>
    );
}

function CafeBox(data){

    return (
        <div className="cafeBox orderScrollBox">
            {data.children}
        </div>
    );
}

function CafeItem(data){

    return (
        <div className={`cafeItem ${data.addClass||""}`}>
            <div className="cafeItem_imgBox">
                <div className="cafeItem_img backgroundImg" style={{backgroundImage:`url('${data.img}')`}}/>
                <button type="button" className="add_cafeItem" onClick={()=>{data.addItem({id:data.id,qty:1,name:data.name})}}><img src="/assets/images/icon/plus.svg"/></button>
                <div className="add_cafeItemCountBox">
                    <CountInput
                        val={data.countVal}
                        qty={data.qty}
                        func={data.countFunc}
                        excessFunc={data.excessFunc}
                        delFunc={data.delFunc}
                    />
                </div>
            </div>
            <div className="cafeItem_info">
                <h2 className="cafeItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            </div>
        </div>
    );
}

function PayBox(data){

    return (
        <div className="payBox orderScrollBox">
            {data.children}
        </div>
    );
}

function PayItem(data){

    return (
        <button type="button" onClick={()=>{data.func()}} disabled={data.disabled||false} className={`payItem ${data.selType ? "active" : ""} ${data.addClass||""}`}>
            {data.img ? <img src={data.img}/> : ""}
            <p className="payItem_text" dangerouslySetInnerHTML={{__html:data.text}}/>
            {data.subText ? <p className="payItem_subText" dangerouslySetInnerHTML={{__html:data.subText}}/> : ""}
        </button>
    );
}

function SelectItemSection(data){

    return (
        <div className="selectItemSection">
            {data.children}
        </div>
    );
}

function SelectItemArea(data){

    return (
        <div className="selectItemArea">
            {data.title ? <h1 className="selectItemArea_title" dangerouslySetInnerHTML={{__html:data.title}}/> : ""}
            {data.children}
        </div>
    );
}

function SelectItem(data){

    return (
        <div className={`selectItem ${data.mainItem ? "mainItem" : ""}`}>
            <p className="selectItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            {data.value ? <p className="selectItem_value" dangerouslySetInnerHTML={{__html:data.value}}/> : ""}
        </div>
    );
}

function WashTypeBox(data) {

    return (
        <div className="washTypeBox orderScrollBox">
            {data.children}
        </div>
    );
}

function WashTypeItem(data){

    return (
        <button type="button" onClick={()=>{if(!data.disabled){data.func()}}} disabled={data.disabled||false} className={`washTypeItem ${data.selType == data.val ? "active" : ""} ${data.disabled ? "washTypeItem_disabled" : ""} ${data.addClass||""}`}>
            <div className="washTypeItem_info">
                <div className="washTypeItem_textBox">
                    <p className="washTypeItem_name gTextColor" dangerouslySetInnerHTML={{__html:data.name}}/>
                    {data.text ? <p className="washTypeItem_text" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
                </div>
                {data.img ? <img src={data.img}/> : ""}
            </div>
            {data.disabled ? 
                <div className="washTypeItem_disabledCover">
                    <img src="/assets/images/img/err_icon.svg"/>
                    <p className="washTypeItem_disabledCoverText">지금은 뷰티 팩토리 서비스 이용이 어려워요.<br/>앱을 통해 예약하거나, 다음에 이용해 주세요.</p>
                </div>
                :""
            }
        </button>
    );
}

export {TicketItem,WashTypeCaption,WashListItem,WashListBox,OptionListBox,OptionListItem,CafeBox,CafeItem,PayBox,PayItem,SelectItemSection,SelectItemArea,SelectItem,WashTypeBox,WashTypeItem};