import { PhoneSetting, birthSetting, comFormat } from "js/function";
import { Fragment, useEffect, useRef, useState } from "react";
import * as fatchSet from "api/api";
import ReactDatePicker from "react-datepicker";
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { forwardRef } from "react";
import { PopupBtnBox } from "./btns";
import { useCallback } from "react";
import { BottomErrMsg, ErrPopup, Popup } from "./popup";

//input box
function InputBoxOnly(data){
    return (
        <div className={`inputItemBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            {data.children}
        </div>
    );
}

function InputSubBoxOnly(data){
    return (
        <div className={`inputSubBoxOnly ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function InputNameOnly(data){
    return (
        <h2 className={`inputName ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.name}}/>
    );
}

//기본 input
function InputItemBox(data){
    const [count,setCount] = useState(0);
    const [pwCh,setPwCh] = useState(true);
    const inputItem = useRef(null);

    function valSetting(e){
        let value = e;

        if(value){
            if(data.regexp == "number"){
                value = value.replace(/[^0-9]/gi,"");
            }else if(data.regexp){
                value = value.replace(data.regexp,"");
            }

            if(data.phoneSetting){
                value = PhoneSetting(value).slice(0, 13)
            }else if(data.registrationType){
                value = value.slice(0, 6)
            }else if(data.birthSetting){
                value = birthSetting(value).slice(0, 10)
            }else{
                value = data.max ? value.slice(0, data.max) : value
            }
        }
        return value ? value : "";
    }

    useEffect(()=>{
        inputItem.current.value = data.value ? valSetting(data.value) : "";
    },[data.pageSetting])

    return (
        <div className={`inputItemBox ${(data.value !== "" || data.value2 !== "") && (data.value || data.value2) ? "active" : ""} ${data.boxAddClass && data.boxAddClass !== "" ? data.boxAddClass : ""}`}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}{data.tooltip||""}</h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className={`inputSet ${data.errMsg && data.errMsg !== "" ? "err" : ""} ${data.unit ? "inputUnitComBox" : ""} ${data.selItem ? "selItem" : ""} ${data.btnName ? "inputComBox" : ""} ${data.maxChk ? "maxChk" : ""} ${data.pwChType ? "pwBox" : ""} ${data.registrationType ? "registrationNumberBox" : ""}`}>
                    <input ref={inputItem} type={data.pwChType ? pwCh ? "password" : "text" : data.inputType ? data.inputType : "text"} onClick={(e) => {if(data.clickInput)data.clickInput(e)}} inputMode={data.inputMode ? data.inputMode : "text"} pattern={data.pattern ? data.pattern : ""} value={valSetting(data.value)} max={data.max && data.max !== "" ? data.max : "null"} className="inputItem" onInput={(e) => {data.func(valSetting(e.target.value))}} onPaste={(e) => {data.func(valSetting(e.target.value))}} onChange={(e) => {data.func(valSetting(e.target.value))}} onKeyUp={(e) => {data.func(valSetting(e.target.value))}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                    {data.cetified ? 
                        <p className="cetifiedTimer">{data.cetified}</p>
                    :""}
                    {data.btnName ? 
                        <button type="button" className={`btn_inputSubBtn ${data.btnAddClass ? data.btnAddClass : ""}`} disabled={data.btnDisabled} onClick={(e) => data.clickEv(e)}>{data.btnName}</button>
                    :""}
                    {data.maxChk ? 
                        <p className="textCount"><span>{count >= data.max ? data.max : count}</span> / {data.max}{data.unit ? data.unit : ""}</p>
                    :""}
                    {data.pwChType ? 
                        <button type="button" className={`btn_pwCh ${pwCh ? "" : "active"}`} onClick={()=>setPwCh(!pwCh)}>{pwCh ? <img src="/assets/images/basic/eye_on.svg"/> : <img src="/assets/images/basic/eye_off.svg"/>}</button>
                    : ""}
                    {data.unit ? <p className="inputUnit">{data.unit}</p> : ""}
                    {data.registrationType ?
                        <>
                        <span>-</span>
                        <div className="registrationNumberBackBox">
                            <input type="number" value={data.value2.slice(0, 1)} onInput={(e) => {data.func2(e.target.value.slice(0, 1))}} onPaste={(e) => {data.func2(e.target.value.slice(0, 1))}} onChange={(e) => {data.func2(e.target.value.slice(0, 1))}} onKeyUp={(e) => {data.func2(e.target.value.slice(0, 1))}} inputMode="numeric" pattern="[0-9]*" className="inputItem" placeholder={data.placeholder2 && data.placeholder2 !== "" ? data.placeholder2 : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
                            <p className="registrationNumberBack_text">******</p>
                        </div>
                        </>
                    : ""}
                    {data.children}
                </div>
                {data.errMsg || data.subBtn ?
                <div className={`inputMsgBox ${data.subBtn && !data.errMsg ? "contentEnd" : ""}`}>
                    {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}
                    {data.subBtn && data.subBtn !== "" ? <button type="button" className="btn_inputSubItem" onClick={()=>data.inputSubFunc()} dangerouslySetInnerHTML={{__html:data.subBtn}}/> : ""}
                </div>
                :""}
                {data.caption && data.caption !== "" ? <p className="inputCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
            </div>
        </div>
    );
}

//customSelect
function CustomSelect(data){
    const [optionOpen,setOptionOpen] = useState(false);
    const box = useRef(null);
    const optionBox = useRef(null);

    const closeClick = useCallback(
        e => {
            let inside = box.current ? box.current.contains(e.target) : true;
            if (!inside){
                setOptionOpen(false);window.removeEventListener("click",closeClick);
            }
        }, [optionOpen, box]
    );

    const onChangeEvn = (name,val,subVal) => {
        data.func(name,val,subVal);
        setOptionOpen(false);
        window.removeEventListener("click",closeClick);
    };

    const onClickEvn = () =>{
        setOptionOpen(!optionOpen);
        setTimeout(() => {
            window.addEventListener("click",closeClick);
        }, 10);
    }

    function valSetting(){
        let setAddr = [...data.options];
        let setData = setAddr.filter((el) => el[data.valKey] == data.value);
        
        return setData.length > 0 ? setData[0][data.nameKey] ? setData[0][data.nameKey] : "" : "";
    }

    useEffect(()=>{
        optionBox.current.scrollTop = 0;
    },[optionOpen]);
    
    return (
        <div className={`inputItemBox ${valSetting() !== "" ? "active" : ""} ${data.boxAddClass && data.boxAddClass !== "" ? data.boxAddClass : ""}`} ref={box}>
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            <div className={`inputBox customSelectInputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""} ${optionOpen ? "active" : ""}`} onClick={onClickEvn}>
                <input type="text" value={valSetting()} className="inputItem" placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={true} disabled={data.disabled ? data.disabled : false}/>
                {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}{data.caption && data.caption !== "" ? <p className="inputCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
            </div>
            <div className={`customSelect_optionBox ${optionOpen ? "active" : ""}`} ref={optionBox}>
                {data.options && data.options?.map((item,i)=>(
                    <button type="button" key={i} className={`customSelect_option ${data.value == item[data.valKey] ? "active" : ""}`} onClick={()=>{onChangeEvn(item[data.nameKey],item[data.valKey],item[data.subValKey]||"")}} dangerouslySetInnerHTML={{__html:item[data.nameKey]}}></button>
                ))}
            </div>
        </div>
    );
}

//textarea
function TextAreaItem(data){
    const [count,setCount] = useState(0);

    function valSetting(e){
        let value = e;
        value = data.max ? e.slice(0, data.max) : e;
        return value;
    }

    function valSettingCount(e){
        setCount(e.length >= data.max ? data.max : e.length);
    }

    return (
        <div className="inputItemBox">
            {data.inputName && data.inputName !== "" ? <h2 className="inputName">{data.inputName}</h2> : ""}
            <div className={`inputBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
                <div className={`textAreaBox ${data.maxChk ? "maxChk" : ""} ${data.btnName ? "textAreaBtnComBox" : ""}`}>
                    <textarea value={valSetting(data.value)||""} className="textareaItem" max={data.max && data.max !== "" ? data.max : "null"} onClick={(e) => {if(data.clickInput)data.clickInput(e)}} onInput={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} onPaste={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} onChange={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} onKeyUp={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} onKeyDown={(e) => {data.func(valSetting(e.target.value));valSettingCount(e.target.value)}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}></textarea>
                    {data.maxChk ? <p className="textareaCount"><span>{count >= data.max ? data.max : count}</span> / {data.max}{data.unit ? data.unit : ""}</p> : ""}
                    {data.btnName ? 
                        <button type="button" className={`btn_inputSubBtn ${data.btnAddClass ? data.btnAddClass : ""}`} disabled={data.btnDisabled} onClick={(e) => data.clickEv(e)}>{data.btnName}</button>
                    :""}
                </div>
                {data.errMsg && data.errMsg !== "" ? <p className="errMsg">{data.errMsg}</p> : ""}{data.caption && data.caption !== "" ? <p className="inputCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
            </div>
        </div>
    );
}

//체크박스
function ChkBox(data){
    let id = `chk_item_${Math.floor(Math.random() * 1000)}`;
    return (
        <div className={`chk_item ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            <input type="checkbox" value={data.val ? data.val : ""} onChange={(e) => data.func(e)} className="chk_itemChk" id={id} checked={data.checkedType||""} disabled={data.disabled ? data.disabled : false}/>
            <label htmlFor={id}>
                {data.children ? data.children : 
                    <p className="chk_item_text" dangerouslySetInnerHTML={{__html:`${data.chkSubType ? `<span>(${data.chkSubType})</span>` : ""} ${data.label}`}}></p>
                }
            </label>
            {data.chkFunc ? <p className="chkBoxBtn" onClick={(e) => data.chkFunc(e)}>{data.chkBtnText ? data.chkBtnText : "보기"}</p> : ""}
            {data.chkTextFunc ? <div className="chkBox_moveText" onClick={(e) => data.chkTextFunc(e)} dangerouslySetInnerHTML={{__html:data.chkText}}/> : ""}
        </div>
    );
}

//라디오박스
function RadioBox(data){
    let id = `${data.id}_${Math.floor(Math.random() * 1000)}`;
    return (
        <div className={`radio_item ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            <input type="radio" name={data.id} value={data.val ? data.val : ""} onChange={(e) => data.func(data.val)} className="radio_itemChk" id={id} checked={data.checkedType||""}/>
            <label htmlFor={id}>
                {data.colorType == "bgFullChk" ? <img src="/assets/images/basic/chk_icon_w.svg"/> : <img src="/assets/images/basic/chk_icon.svg"/>}
                <p className="radio_item_text" dangerouslySetInnerHTML={{__html:`${data.label}`}}></p>
            </label>
        </div>
    );
}

export {InputBoxOnly, InputSubBoxOnly, InputNameOnly, InputItemBox, ChkBox, TextAreaItem, RadioBox, CustomSelect}