//목록 없을시
function NotItems(data){
    return (
        <div className={`notItemArea ${data.addClass ? data.addClass : ""}`}>
            {data.img ? <img src={data.img}/> : ""}
            <p className="notItemText" dangerouslySetInnerHTML={{__html:data.text}}></p>
        </div>
    );
}

export {NotItems};